/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
  This brand theme will get imported into the site theme and the two will be merged using lodash.merge.
*/
module.exports = {
  colors: {
    primary: '#0A73EB',
    primaryalt: '#1990FF',
    primarydark: '#011251',
    secondary: '#0D71CD',
    tertiary: '#3905A5',
    alt: '#02168C', // TODO: look into this. I'd like this to be something like "primaryLight", but for some reason, the core isn't pulling the value if I use words like primary, secondary, etc
    pink: '#F104BA',
    dark: '#000000',
    gray: '#212121',
    light: '#F9F9F9',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
  },
  gradients: {
    primary: 'linear-gradient(to right, #FFC800, #FA783C)',
    secondary: 'linear-gradient(to right, #FA783C, #FA4628)',
    tertiary: 'linear-gradient(to right, #FFC800, #FA4628)',
  },
  alternateBgColor: false,
  prominent: 'primary',
  fonts: {
    headings: {
      family: 'Poppins Light, sans-serif',
      lg: {
        weight: 400,
      },
      md: {
        weight: 400,
      },
      sm: {
        weight: 400,
      },
    },
    subheadings: {
      family: 'Poppins Medium, sans-serif',
    },
    body: {
      family: 'Poppins Regular, sans-serif',
    },
    links: {
      family: 'Poppins SemiBold, sans-serif',
    },
  },
  backgroundColors: {
    dark: {
      textColor: '#ffffff',
    },
    primary: {
      textColor: '#ffffff',
    },
    secondary: {
      textColor: '#ffffff',
    },
  },
  components: {
    Button: {
      family: 'Poppins SemiBold, sans-serif',
      rounded: 'max',
      variants: {
        primary: {
          color: 'white',
        },
        secondary: {
          color: 'white',
        },
        tertiary: {
          color: 'white',
        },
        dark: {
          color: 'white',
        },
        pink: {
          color: 'white',
        },
      },
    },
    Column: {
      image: 'full',
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
