import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      body .leshen-header-desktop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
      }

      img[alt='Logo'] {
        display: block;
        width: 155px;
        height: 30px;
      }
    `}
  />
))

export default HeaderStyles
